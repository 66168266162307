import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import thermalPaper from './objs/thermal_paper.glb'
import {gsap} from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { test } from './menu_script';
import roll_1 from './images/55mms.png'
import roll_2 from './images/cropped/57x34.webp'
import roll_3 from './images/cropped/57x40.webp'
import roll_4 from './images/cropped/80x35.webp'
import roll_5 from './images/cropped/80x80.webp'
import roll_6 from './images/80_180mm.png'

/* Import locomotive-scroll */
// Vendors


import LocomotiveScroll from "locomotive-scroll";

/* Locomotive scroll instance */
const locomotiveScroll = new LocomotiveScroll();

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
const mobile_view = window.matchMedia('(max-width:900px)')
const mobile_view_landscape = window.matchMedia('(max-width:900px) and (orientation:landscape)')
const large_viewport = window.matchMedia('(min-width:1919px)')
const whatsapp = $('.whatsapp')

const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
renderer.setSize( window.innerWidth, window.innerHeight );
renderer.autoClear = false;
renderer.setClearColor('#000000');
var threeD_viewport = document.querySelector('.threeD_viewport');


threeD_viewport.appendChild(renderer.domElement );

const geometry = new THREE.BoxGeometry( 1, 1, 1 );
const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
const cube = new THREE.Mesh( geometry, material );
//scene.add( cube );

const light = new THREE.AmbientLight( 0xffffff,.5); // soft white light
scene.add( light );

const directionalLight = new THREE.DirectionalLight( 0xffffff, .75 );
directionalLight.position.set( 10, -270, 10 );

scene.add( directionalLight );

const loader = new GLTFLoader();
let loadModel;
loader.load( thermalPaper, function ( gltf ) {
    loadModel = gltf;
    //gltf.scene.position.y = -1
    gltf.scene.rotation.y = Math.PI/2
    gltf.scene.rotation.z = Math.PI/4
    gltf.scene.scale.set(1.2,1.2,1.2);

    if(mobile_view.matches){
      gltf.scene.scale.set(1,1,1);
    }

    

	scene.add( gltf.scene );

    var tl = new gsap.timeline();
    var tl2 = new gsap.timeline({delay:2.5,repeat:-1,ease:"none"});


   //Switch
   

    if(mobile_view.matches){
      tl.fromTo(gltf.scene.position,{
        y:-4,
        x:.1,
        duration:1.5
    },{
      y:.7,
      duration:1.5
    })
    }else{
        tl.fromTo(gltf.scene.position,{
          y:-4,
          x:.5,
          duration:1.5
        },{
          y:.7,
          duration:1.5
        })
    }

    if(mobile_view_landscape.matches){

      tl.fromTo(gltf.scene.position,{
        y:-4,
        x:.1,
        duration:1.5
    },{
      y:1.3,
      duration:1.5
    })
    
    }


    // tl2.to(gltf.scene.position,{
    //   y:1,
    //   duration:4
    // })

    // tl2.to(gltf.scene.position,{
    //   y:1,
    //   duration:4,
    
    // }) 

}, undefined, function ( error ) {

	console.error( error );

} );

const controls = new OrbitControls( camera, renderer.domElement );
controls.enableZoom = false;
controls.enablePan = false;
camera.position.z = 7;
controls.update();

function animate() {
    if(loadModel){
        loadModel.scene.rotation.y += 0.005;
        //loadModel.scene.rotation.x += 0.001;


    }
    //cube.rotation.x += 0.01;
	//cube.rotation.y += 0.01;

	requestAnimationFrame( animate );
	renderer.render( scene, camera );

    controls.update();
}
animate();

//GSAP animations
var arrow_down = document.querySelector('.down_arrow');
var tl = new gsap.timeline({repeat:-1});
var model_tl = gsap.timeline();

gsap.ticker.add(() => {
  renderer.render(scene, camera);
});

tl.to(arrow_down,{
    y:15,
    alpha:0,
    duration:2
})

//Scroll Animations
gsap.registerPlugin(ScrollTrigger)
const rolls_sizes_1 = document.querySelector('.rolls_sizes')
const roll_video_1 = rolls_sizes_1.querySelector('video')
const rolls = $('.rolls')

let scrollPosition = 0;
let delay = 0;
let accelaration_amount = .05;

ScrollTrigger.create({
    trigger: rolls_sizes_1,
    start: "top-=250 top",
    //endTrigger: "#otherID",
    end: `bottom `,
    //pin:true,
    //markers:true,
    //scrub:true,
    onToggle: self => console.log("toggled, isActive:", self.isActive),
    onUpdate: self => {
      //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
      scrollPosition = (self.progress * 22);
      
      delay +=(scrollPosition - delay) * accelaration_amount;
      
    }
  });

  setInterval(()=>{
    roll_video_1.currentTime = delay;
  },40)

  rolls.html('<span>'+rolls.html().split("").join("</span><span>")+"</span>")
  gsap.set($('.rolls span'),{
    alpha:1,
    top:'80px'
    
  })

  const gradient_background = $('.gradient_background')
 
const play_once_check = 1;
var play_index = 1;
const carousel_container = $('.carousel_container')
 

$(window).scroll(function(){
    var scrollTop = $(window).scrollTop()
    const  banner_caption = $('.banner_caption')
    const application_content = $('.application_content')
    const rolls_holder = $('.rolls_sizes .container')
    const shop_button = $('.shop_button')
    

    var opacity = Math.max(0,(scrollTop - (banner_caption.offset().top))/(scrollTop))
   

    if(scrollTop > banner_caption.offset().top-300){
        gsap.to(rolls,{
            alpha:1,
            //y:0,
            duration:1.8,
            paddingTop:0,
            //stagger:.1,
            //top:0,
            delay:1,
            ease:"power4.out"
          })

          gsap.to($('.rolls_description'),{
            alpha:1,
            duration:1,
            //stagger:.1,
            
            delay:1.5,
           
            //ease:"power4.out"
          })

          gsap.to(shop_button,{
            alpha:1,
            
            duration:1,
            //stagger:.1,
            
            delay:1.7
            //ease:"power4.out"
          })

         
         
    }

    if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top)) /$(window).height()){
      var opacity = Math.min(1,(scrollTop - (100 * (banner_caption.offset().top + 3500)) /$(window).height())/(scrollTop))
      gradient_background.css({
        'background-image':`radial-gradient(circle,rgba(0,0,0,${(.8-opacity*1.5)}),rgba(0,0,0,${(1-opacity*1.5)}),rgba(0,0,0,${1-opacity}),rgba(0,0,0,${.5-opacity}),rgba(0,0,0,${.8-opacity}),rgba(0,0,0,0.0),rgba(0,0,0,0.0),rgba(0,0,0,0.0),rgba(0,0,0,0.0))`
        
      })

    }

    if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top+ 2000)) /$(window).height()){
      
      //alert()
      var move = (scrollTop-(banner_caption.offset().top + (100 * (banner_caption.offset().top + 1500)) /$(window).height())) - (scrollTop/25)
      var opacity = Math.max(0,(scrollTop - (banner_caption.offset().top +  (100 * (banner_caption.offset().top + 2000)) /$(window).height()))/(scrollTop))
      rolls_holder.css({
        'transform':`translateY(-${move/10}%)`,
        opacity:`${1-opacity*10}`
      })
     
    }



    if(scrollTop > banner_caption.offset().top + 1000){
      var opacity = Math.min(1,(scrollTop - (banner_caption.offset().top+1000))/(scrollTop))
      gradient_background.css({
        'background-image':`radial-gradient(circle,rgba(0,0,0,${(opacity)}),rgba(0,0,0,${1.2-opacity}),rgba(0,0,0,${1-opacity}),rgba(0,0,0,${1-opacity}),rgba(0,0,0,0.0),rgba(0,0,0,0.0),rgba(0,0,0,0.0),rgba(0,0,0,0.0))`
    
      })
    }

    const first_vid = $('#first_vid')

    if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 3000)) /$(window).height()){
      //alert()
      var scale = (scrollTop-(banner_caption.offset().top + (100 * (banner_caption.offset().top + 2500)) /$(window).height()))/(scrollTop/25)
      first_vid.css({
        'transform':`scale(${1+scale/60})`
      })

      //alert('application')
      application_content.css({
        'opacity':'1',
        'transition': 'opacity .5s'
      })
    }else{
      application_content.css({
        'opacity':'0',
        'transition': 'opacity .3s'
      })
    }

    if(scrollTop > banner_caption.offset().top + 2600){
      var opacity = Math.min(1,(scrollTop - (banner_caption.offset().top))/(scrollTop))
      gradient_background.css({
        'background-image':`radial-gradient(circle,rgba(0,0,0,${(-opacity)}),rgba(0,0,0,${1.2-opacity}),rgba(0,0,0,${1.1-opacity}),rgba(0,0,0,${1.1-opacity}),rgba(0,0,0,0.2),rgba(0,0,0,0.2),rgba(0,0,0,0.0),rgba(0,0,0,0.0))`
        
      })
    }
    
    if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 4500)) /$(window).height()){
     
      carousel_container.css({
        opacity:1,
        'transform':'translateY(0)'
      })
    
    }
    

    if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 3500)) /$(window).height()){
      const applicant_carousel = $('#applicant_carousel')
      var move = (scrollTop-(banner_caption.offset().top + (100 * (banner_caption.offset().top + 3500)) /$(window).height())) - (scrollTop/25)
      var opacity = Math.max(0,(scrollTop - (banner_caption.offset().top + 5000))/(scrollTop))
      application_content.css({
        'transform':`translateY(-${move/4}%)`,
        //opacity:`${1-opacity*10}`
      })

      carousel_container.css({
        opacity:1
      })

      // applicant_carousel.css({
      //   'transform':`translateY(-${move/150}%)`,
      // })

    
    }

    if(window.matchMedia('(max-width:400px)').matches){

      if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 1000)) /$(window).height()){
        //alert()
        const applicant_carousel = $('#applicant_carousel')
        var move = (scrollTop-(banner_caption.offset().top + (100 * (banner_caption.offset().top + 2700)) /$(window).height())) - (scrollTop/25)
        var opacity = Math.max(0,(scrollTop - (banner_caption.offset().top + 5000))/(scrollTop))
        application_content.css({
          'transform':`translateY(-${move/4}%)`,
          //opacity:`${1-opacity*10}`
        })
  
        carousel_container.css({
          opacity:1
        })
  
        // applicant_carousel.css({
        //   'transform':`translateY(-${move/150}%)`,
        // })
  
        console.log(move)
      
      }

      if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 2500)) /$(window).height()){
        //alert()
        var scale = (scrollTop-(banner_caption.offset().top + (100 * (banner_caption.offset().top + 2500)) /$(window).height()))/(scrollTop/25)
        first_vid.css({
          'transform':`scale(${1+scale/60})`
        })
  
        //alert('application')
        application_content.css({
          'opacity':'1',
          'transition': 'opacity .2s'
        })
      }else{
        application_content.css({
          'opacity':'0',
          'transition': 'opacity .2s'
        })
      }

    }

    if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 10000)) /$(window).height()){
       const sustainability = $('.sustainability')
       const sus_video = $('.sus_video')
       
       sustainability.css({
        'opacity':'1',
        'transition':'1s',
        "top": `65%`
       })

       if(play_index == play_once_check){
        sus_video[0].play()
        play_index++;
       }

     
    }

    //About Page

    const second_content = $('.second_content')
    

    //Media Quries
    const mid_screen = window.matchMedia('(min-width:1918px)')

    if(mid_screen.matches){

      ScrollTrigger.create({
        trigger: rolls_sizes_1,
        start: "top-=550 top",
        //endTrigger: "#otherID",
        end: `bottom `,
        //pin:true,
        //markers:true,
        //scrub:true,
        onToggle: self => console.log("toggled, isActive:", self.isActive),
        onUpdate: self => {
          //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
          scrollPosition = (self.progress * 22);
          
          delay +=(scrollPosition - delay) * accelaration_amount;
          
        }
      });
     

      if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top+ 1000)) /$(window).height()){
      
        //alert()
        var move = (scrollTop-(banner_caption.offset().top + (100 * (banner_caption.offset().top + 1500)) /$(window).height())) - (scrollTop/25)
        var opacity = Math.max(0,(scrollTop - (banner_caption.offset().top +  (100 * (banner_caption.offset().top + 2000)) /$(window).height()))/(scrollTop))
        rolls_holder.css({
          'transform':`translateY(-${move/10}%)`,
          opacity:`${1-opacity*10}`
        })
  
        console.log(move)
       
      }
    }

    if(mobile_view.matches){
     
      if(scrollTop > 10){
        whatsapp.css({
          opacity:1,
          visibility:'visible'
        })
      }else{
        whatsapp.css({
          opacity:0,
          visibility:'hidden'
        })
      }

      if(scrollTop > carousel_container.offset().top){
        whatsapp.css({
          opacity:0,
          visibility:'hidden'
        })
      }

      if(scrollTop > banner_caption.offset().top-300){
        gsap.to($('.rolls span'),{
            alpha:1,
            y:0,
            duration:1.5,
            //stagger:.1,
            top:0,
            delay:1,
            ease:"power4.out"
          })

          gsap.to($('.rolls_description'),{
            alpha:1,
            duration:1,
            //stagger:.1,
            delay:1.5
            //ease:"power4.out"
          })

          gsap.to(shop_button,{
            alpha:1,
            
            duration:1,
            //stagger:.1,
            
            delay:1.7
            //ease:"power4.out"
          })

         
         
    }
      

      if(scrollTop > banner_caption.offset().top + 700){
        
        carousel_container.css({
          opacity:1,
          'transform':'translateY(0)'
        })
      
      }

      if(scrollTop > banner_caption.offset().top + 1000){
        const sustainability = $('.sustainability')
        const sus_video = $('.sus_video')
        
        sustainability.css({
         'opacity':'1',
         'transition':'1s',
         "top": `60%`
        })
 
        if(play_index == play_once_check){
         sus_video[0].play()
         play_index++;
        }
 
       
      
     }

     if(scrollTop > banner_caption.offset().top + (100 * (banner_caption.offset().top + 5000)) /$(window).height()){
   
      //alert('application')
      application_content.css({
        'opacity':'0',
      })
    }
    }

    

  
    
})

const arrow_left = $('.arrow_left')
const arrow_right = $('.arrow_right')
const carousel = $('.carousel')
const printer = $('.printer')
const cash_register = $('.cash_register')
const carousel_header = $('.carousel_header')
const carousel_index = $('.carousel_index')
const carousel_content = $('.carousel_content')
const scroll_button = $('#scroll_button')
const total_index = 3
var index=1
var move=1;
var value =34

arrow_right.click(function(){
  if(index < total_index){
    move+=value

    carousel.css({
      transform: `translateX(-${move}%)`
    })


    index++;

    if(index==2){
      printer.css('opacity',"1")
      carousel_header.text('Receipt Printer')
      carousel_index.text(`0${index}`)
      carousel_content.text('Our premium thermal paper rolls set the standard for fast, clear, and long-lasting receipts.')
    }else{
      printer.css('opacity',".3")
    }

    if(index==3){
      cash_register.css('opacity',"1")
      carousel_header.text('ATM Machines ')
      carousel_index.text(`0${index}`)
      carousel_content.text('In the heart of every ATM transaction is a small yet crucial detail, thermal paper. ')
    }else{
      cash_register.css('opacity',".3")
    }

  }
})

arrow_left.click(function(){

  if(index > 1 && index <= total_index ){

    index--;
    move-=value

    carousel.css({
      transform: `translateX(-${move}%)`
    })

    if(index == 1){
      carousel_header.text('Cash Register')
      carousel_index.text(`0${index}`)
      carousel_content.text(`Our business understands the value of seamless transactions. That's why we rely on providing cutting-edge thermal paper for your cash register machines.`)
    }

    if(index==2){
      printer.css('opacity',"1")
      carousel_header.text('Receipt Printer')
      carousel_content.text('Our premium thermal paper rolls set the standard for fast, clear, and long-lasting receipts.')
    }else{
      printer.css('opacity',".3")
      carousel_index.text(`0${index}`)
    }

    if(index==3){
      cash_register.css('opacity',"1")
     
      
    }else{
      cash_register.css('opacity',".3")
      carousel_index.text(`0${index}`)
    }

    console.log(index)

  }

})

arrow_left.on('mousedown', function(){
  $(this).css('width','4%')
})

arrow_left.on('mouseup', function(){
  $(this).css('width','4.5%')
})

arrow_right.on('mousedown', function(){
  $(this).css('width','4.5%')
})

arrow_right.on('mouseup', function(){
  $(this).css('width','5%')
})

//Arrow Top
const arrow_upwards = $('.arrow_upwards')
arrow_upwards.click(function(){
  $('html, body').animate({scrollTop:0},1500,'swing');
})



if(mobile_view.matches){

  scroll_button.click(function(){
    $('html, body').animate({
      scrollTop: $(".vid_container").offset().top
  },1000,'swing');
  
  })

  
}else{

  if(large_viewport.matches){

    scroll_button.click(function(){
     
      $('html, body').animate({
        scrollTop: $(".vid_container").offset().top-300
    },3000,'swing');
    
      
    
    })
  
  }else{

    scroll_button.click(function(){
   
      $('html, body').animate({
        scrollTop: $(".vid_container").offset().top-100
    },3000,'swing');
    
      
    
    })

  }

 

}





$(window).on('load',()=>{
    const logo = $('.navigation .flex1 img')
    const navs = $('.navigation .flex2 img')
    const banner_caption = $('.banner_caption')
    const social_section = $('.social_section')
    

    gsap.set(logo,{
      alpha:0
    })

    gsap.set(navs,{
      alpha:0,
      y:40
    })

    gsap.set(social_section,{
      alpha:0,
      
    })

    gsap.set(whatsapp,{
      alpha:0,
      
    })

    gsap.to(logo,{
      alpha:1,
      delay:.2
    })

    gsap.to(navs,{
      alpha:1,
      delay:.4,
      stagger:.2,
      y:0
    })

    gsap.to(banner_caption,{
      alpha:1,
      delay:1,
      duration:1
    })

    gsap.to([social_section,whatsapp],{
      alpha:1,
      delay:1.3,
      duration:1.2
    })
})


//Menu Interactivity
test()

var range = $('.range')
const rolls_sizes = $('.image_holder img ')
const rolls_sizes_text = $('.rolls_sizes_text')
const range_content = $('.range_content')
var text
var recommendation
let src
range.change(function(){
  switch(parseInt(range.val())){
    case 1:
      src=roll_1
      text='57mm X 30mm'
      recommendation='Recommended for POS machines'

      break
    case 2:
      src=roll_2
      text='57mm X 35mm'
      recommendation='Recommended for POS machines'
      break
    case 3:
      src=roll_3
      text='57mm X 40mm'
      recommendation='Recommended for POS machines'
      break
    case 4:
      src=roll_4
      text='80mm X 30mm'
      recommendation='Recommended for cash registers'
      break
    case 5:
      src=roll_5
      text='80mm X 80mm'
      recommendation='Recommended for cash registers'
      break
    case 6:
      src=roll_6
      text='80mm X 180mm'
      recommendation='Recommended for ATMs'
      break    

}
console.log(text)
  rolls_sizes_text.text(text)
  rolls_sizes.attr('src',src)
  range_content.text(recommendation)
})

// if(mobile_view.matches){
//   //Carousel Swipe Animation
// var carousel_swipe = document.querySelector('.cash_register_atm .flex:nth-child(2)')

// carousel_swipe.addEventListener('touch',(e)=>{
//   e.preventDefault()
//   const delta = e.deltaX
//   console.log(delta)

//   carousel_swipe.scrollBy({
//     left:delta
//   })
// })

//  //Swipe to chnage pictures (Mobile touch gallery)

// carousel.on('touchstart',TouchStart);
// carousel.on('touchmove',TouchMove); 

// var gallery_index = 0
// var app_length =3
// const printer = $('.printer')
// const cash_register = $('.cash_register')
// const atm = $('.atm')
// const carousel_header = $('.carousel_header')
// const carousel_content = $('.carousel_content')
// const carousel_index = $('.carousel_index')

//  var initialX = null;
//  var initialY = null;

//  function TouchStart(e) {
//      initialX = e.touches[0].clientX;
//      initialY = e.touches[0].clientY;
//  };

//  function TouchMove(e) {
//      if (initialX === null) {
//        return;
//      }

//      if (initialY === null) {
//        return;
//      }

//      var currentX = e.touches[0].clientX;
//      var currentY = e.touches[0].clientY;

//      var diffX = initialX - currentX;
//      var diffY = initialY - currentY;

//      if (Math.abs(diffX) > Math.abs(diffY)) {
//       console.log(diffX)
//        // sliding horizontally
//        if (diffX > 0) {
       
//          // swiped left
//          if(gallery_index < app_length-1){
//           printer.css({
//             'opacity':1
//           })

//           atm.css('opacity',.1)
//           carousel_header.text('Receipt Printer')
//           carousel_content.text('Our premium thermal paper rolls set the standard for fast, clear, and long-lasting receipts.')

//           carousel_index.text('02')

//           if(gallery_index == 1){
//             carousel_index.text('03')

//             //carousel_index(gallery_index)

//             printer.css({
//               'opacity':.3
//             })

//             cash_register.css({
//               'opacity':1
//             })

//             carousel_header.text('ATM Machines')
//             carousel_content.text('In the heart of every ATM transaction is a small yet crucial detail, thermal paper.')
//           }
//            gallery_index++
//        }

//        } else {
//          // swiped right
//          if(gallery_index < app_length && gallery_index > 0){
//          console.log(gallery_index)
//           if(gallery_index == 2){

//             carousel_index.text('02')

//             printer.css({
//               'opacity':1
//             })

//             cash_register.css({
//               'opacity':.3
//             })

//             carousel_header.text('Receipt Printer')
//             carousel_content.text('Our premium thermal paper rolls set the standard for fast, clear, and long-lasting receipts.')

//           }
          

//           if(gallery_index == 1){
//             carousel_index.text('01')
//             printer.css({
//               'opacity':.3
//             })

//             cash_register.css({
//               'opacity':.3
//             })

//             atm.css('opacity',1)

//             carousel_header.text('Point of Sale (POS)')
//             carousel_content.text('In the world of point of sale devices, every detail matters. Our thermal paper rolls deliver clarity, speed, and reliability, ensuring seamless operations.')
//           }

//           gallery_index--
//        }
       
         

//        }  
//      } 

//      initialX = null;
//      initialY = null;

//      e.preventDefault();
//    };

// }

var carousel_swipe = document.querySelector('.cash_register_atm .flex:nth-child(2)')
var gallery_index = 0
var app_length =3
const atm = $('.atm')


let touchstartX = 0
let touchendX = 0
    
function checkDirection() {
  if (touchendX < touchstartX){
    //alert('swiped left!')

      // swiped left
         if(gallery_index < app_length-1){
          printer.css({
            'opacity':1
          })

          atm.css('opacity',.1)
          carousel_header.text('Receipt Printer')
          carousel_content.text('Our premium thermal paper rolls set the standard for fast, clear, and long-lasting receipts.')

          carousel_index.text('02')

          if(gallery_index == 1){
            carousel_index.text('03')

            //carousel_index(gallery_index)

            printer.css({
              'opacity':.3
            })

            cash_register.css({
              'opacity':1
            })

            carousel_header.text('ATM Machines')
            carousel_content.text('In the heart of every ATM transaction is a small yet crucial detail, thermal paper.')
          }
           gallery_index++
       }

       
  } 


  //Swiped Right

  if (touchendX > touchstartX) {

    if(gallery_index < app_length && gallery_index > 0){
               console.log(gallery_index)
                if(gallery_index == 2){
      
                  carousel_index.text('02')
      
                  printer.css({
                    'opacity':1
                  })
      
                  cash_register.css({
                    'opacity':.3
                  })
      
                  carousel_header.text('Receipt Printer')
                  carousel_content.text('Our premium thermal paper rolls set the standard for fast, clear, and long-lasting receipts.')
      
                }
                
      
                if(gallery_index == 1){
                  carousel_index.text('01')
                  printer.css({
                    'opacity':.3
                  })
      
                  cash_register.css({
                    'opacity':.3
                  })
      
                  atm.css('opacity',1)
      
                  carousel_header.text('Point of Sale (POS)')
                  carousel_content.text('In the world of point of sale devices, every detail matters. Our thermal paper rolls deliver clarity, speed, and reliability, ensuring seamless operations.')
                }
      
                gallery_index--
              }
    
  }
}

carousel_swipe.addEventListener('touchstart', e => {
  touchstartX = e.changedTouches[0].screenX
})

carousel_swipe.addEventListener('touchend', e => {
  touchendX = e.changedTouches[0].screenX
  checkDirection()
})



  


